<template>
  <div class="starter-page" >
    
    <div class="section text-center" >
      <video-background id="video_bg" src="https://ai-cast-video-bucket.s3.ap-northeast-2.amazonaws.com/Dance.mp4"
      style="max-height: 100vh; height: 100vh; top:0;"
      poster="https://ai-cast-video-bucket.s3.ap-northeast-2.amazonaws.com/rpg.png"
      >
        <br><br><br>
        <h1 style="color: white;">Red Pill Go</h1>
        <h3 style="color: white;">Live Avatars In Your Pocket</h3>
        <div style="width: 100%; vertical-align: middle; bottom: 0; position: absolute; height: 50%;">
          <soft-button color="gored" variant="gradient" class="center" @click="OnLaunch">
            Launch Red Pill Go
          </soft-button>
        </div>
        <div class="flex social-btns">

          <a class="app-btn blu flex vert" style="cursor:pointer" @click="OnDesktop">
            <font-awesome-icon icon="fa-brands fa-windows" />
            <p>Application <br/> <span class="big-txt">Desktop</span></p>
          </a>
          <a class="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.next.redpillgo">
            <font-awesome-icon icon="fa-brands fa-google-play" />
            <p>Get it on <br/> <span class="big-txt">Google Play</span></p>
          </a>
        </div>
        
      </video-background >
      <iframe id="my_iframe" style="display:none;"></iframe>
    </div>
  </div>
</template>

<script>
//import { Auth } from 'aws-amplify';

import VideoBackground from 'vue-responsive-video-background-player'
import SoftButton from "@/components/SoftButton.vue";
import { mapMutations } from "vuex";



export default {
  name: 'Home',
  data() {
    return{
      logged: false,
    }
  },
  created: function()
  {
    
  },
  async mounted() {
      
      //this.$refs.videoRef.src = "../assets/video/Dance.mp4";
      //this.$refs.videoRef.play();
      this.closeAllPanels();
      this.$store.state.hasSlides = false;
      this.$store.state.has3dEnvironment = false;
      //document.getElementById("video_bg").style.height =  window.innerHeight+"px";
      window.addEventListener('orientationchange', function() {
        console.log(window.orientation);
        if(window.orientation==0)
        {
          //console.log("portrait",window.innerHeight)
          if(document.getElementById("video_bg")!=undefined)
          document.getElementById("video_bg").style.height =  window.innerHeight+"px";
        }
        else
        {
          //console.log("landscape",window.innerHeight)
          if(document.getElementById("video_bg")!=undefined)
          document.getElementById("video_bg").style.height =  window.innerHeight+"px";
        }
      }, false);
      window.addEventListener("resize",function() {
        //console.log("resize ",event,window.innerHeight);
        if(document.getElementById("video_bg")!=undefined)
        document.getElementById("video_bg").style.height =  window.innerHeight+"px";
      });
      setTimeout(()=> {
        //console.log("init ",window.innerHeight);
        if(document.getElementById("video_bg")!=undefined)
        document.getElementById("video_bg").style.height =  window.innerHeight+"px";
      }
      ,1000);
      if(document.getElementById("video_bg")!=undefined)
        document.getElementById("video_bg").style.height =  window.innerHeight+"px";
      
  },
  methods:
  {
    ...mapMutations(["showNavbar", "closeAllPanels"]),
    OnLaunch()
    {
      this.$router.push('/projects')
    },
    OnDesktop()
    {
      document.getElementById('my_iframe').src = "https://redpillgohub.s3.ap-northeast-2.amazonaws.com/install/RedPillGoHubSetup.exe";
    },
    // async isLoggedIn()
    // {   
    //   //console.log("try to add fullscreen event listener");
    //   try 
    //   {     
    //     var user = await Auth.currentAuthenticatedUser();
    //     this.user_initials = user.attributes.email.substring(0, 1);
    //     //if(!this.is_logged)
    //     //  this.$root.$emit('check_login')
    //     return true   
    //   }   
    //   catch(e) 
    //   {  
        
    //     return false   
    //   } 
    // },
  },
  components: {
    VideoBackground,
    SoftButton,
  }
};
</script>
<style>
.starter-page {
  min-height: calc(100vh ); 
  height: 50%!important;
  height: -webkit-fill-available;
  width: 100%;
  background-color:cadetblue;
}
.center {  
 
  position: relative;
  vertical-align: middle;
  bottom: 10%;
  
  
}  
video {
  position: absolute;
  height: 100%;
  top: 0%;
  left: 0%;

  /* -ms-transform: translate(-50%, 0%); */
  /* -webkit-transform: translate(-50%, 0%); */
  /* transform: translate(-50%, 0%); */
  text-align: center;
  z-index: 1;

}


</style>
